$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$body-bg: #333;
$body-color: #3c3c3c;
$headings-color: #535353;
$headings-font-weight: bold;

$primary: #0A63A0;

$link-color: $primary;
$link-hover-color: $primary;

$navbar-padding-y: 0;
$navbar-nav-link-padding-x: 1rem;

$nav-tabs-link-active-bg: $primary;

$navbar-light-color:                #333;
$navbar-light-hover-color:          #fff;
$navbar-light-active-color:         #fff;
$navbar-light-disabled-color:       rgba($navbar-light-color, .3);

$navbar-bg: #EFEFEF;
$navbar-link-hover-bg: darken($primary, 10%);
$navbar-link-active-bg: darken($primary, 10%);

$hamburger-color: #fff;

$story-headings-color: #535353;

@import "../../fonts/oswald";
@import "../../fonts/open-sans";
@import "../../magazine";

.brand-logo img {
  height: 90px;
}

.navbar-toggler {
  background-color: #5B6976;
  .icon-hamburger {
    max-width: 29px;
  }
  &.collapsed {
    .line-1,
    .line-3 {
      width: 29px;
    }
  }
  &:not(.collapsed) {
    // button shall fill the 29px box thus diagonal lines need to be longer and can't be 45deg straight
    .line-1,
    .line-3 {
      width: 35px;
    }
    .line-1 {
      transform: rotate(35deg);
    }
    .line-3 {
      transform: rotate(-35deg);
    }
  }
}


.navbar {
  background-color: $body-bg;

  .nav-link {
    color: white;
    font-family: $font-family-sans-serif;
    text-transform: uppercase;

    @include hover-focus {
      background-color: $nav-tabs-link-active-bg;
    }
  }

}

@include media-breakpoint-up(sm) {
  .navbar {
    .pur-mgmt {
      ul.dropdown-menu {
        background-color: $navbar-bg;
        margin-top: 1px;
      }
      li.nav-item {
        border-top: none !important;
        border-bottom: 1px solid $black;
      }
    }
  }
}

.navbar.navbar-expand .nav-item {
  @include nav-item-separator(#fff, $navbar-link-font-size);
}

.navbar.navbar-light {
  border-top: 1px solid $black;
  border-bottom: 1px solid $black;
  background-color: $navbar-bg;
}

.sidebar .card.filled {
  background-color: $navbar-bg;
}

.section-caption {
  color: $story-headings-color;
  font-weight: normal;
  font-family: 'Oswald', sans-serif;
}

.teaser-title {
  font-weight: $font-weight-bold;
}

.article-head {
  h1 {
    font-weight: 600;
  }
  p {
    color: #777;
    font-weight: normal;
  }
}

header  {
  background-color: transparent;
}

.navbar-top {
  background-size: auto 100%;
  background: #5B6976 url(../../../img/maennerseite/bg_header.png) no-repeat center center;

  .brand-logo img {
    height: auto;
  }
}

.navbar.navbar-expand .nav-item + .nav-item::after {
  display: none;
}
